import type { StrapiImage } from '@cardo/types';

export function generateSrcset(img: StrapiImage) {
  const formats = img.data.attributes.formats;

  if (!formats) return;

  const sizes = Object.keys(formats).sort((a, b) => {
    return formats[a].width - formats[b].width;
  });

  let srcSet = '';

  sizes.forEach((size, idx) => {
    srcSet =
      srcSet +
      `${formats[size].url} ${formats[size].width}w${
        idx !== sizes.length - 1 ? ', ' : ''
      }`;
  });

  return srcSet;
}

export function generateSizes() {
  return '100vw';
}

export function getSrc(img: StrapiImage) {
  if (
    typeof document === 'undefined' &&
    process.env.NODE_ENV === 'production' &&
    process.env.MEDIA_S3_URL &&
    process.env.MEDIA_CLOUDFRONT_URL
  ) {
    try {
      const url = new URL(img.data.attributes.url);
      const host = url.host;

      if (host === process.env.MEDIA_S3_URL) {
        return url.href.replace(
          process.env.MEDIA_S3_URL,
          process.env.MEDIA_CLOUDFRONT_URL
        );
      }
    } catch (err) {
      console.error('Failed to replace s3 url with cloudfront url: ', err);
    }
  }

  if (
    typeof document !== 'undefined' &&
    window.ENV?.NODE_ENV === 'production' &&
    window.ENV?.MEDIA_S3_URL &&
    window.ENV?.MEDIA_CLOUDFRONT_URL
  ) {
    try {
      const url = new URL(img.data.attributes.url);
      const host = url.host;

      if (host === window.ENV.MEDIA_S3_URL) {
        return url.href.replace(
          window.ENV.MEDIA_S3_URL,
          window.ENV.MEDIA_CLOUDFRONT_URL
        );
      }
    } catch (err) {
      console.error('Failed to replace s3 url with cloudfront url: ', err);
    }
  }

  return img.data.attributes.url || '';
}

export function getAlt(img: StrapiImage) {
  return img.data.attributes.alternativeText || img.data.attributes.name || '';
}

export function getHeight(img: StrapiImage) {
  return `${img.data.attributes.height}px` || '';
}

export function getWidth(img: StrapiImage) {
  return `${img.data.attributes.width}px` || '';
}

export function generateImgProps(img: StrapiImage): {
  srcSet: string | undefined;
  src: string;
  sizes: string;
  width: string;
  height: string;
} {
  return {
    srcSet: generateSrcset(img),
    src: getSrc(img),
    sizes: generateSizes(),
    width: getWidth(img),
    height: getHeight(img),
  };
}
